import React from 'react';

export default function VerticalDivider({
  gap = 10,
  height = 28
}: {
  readonly gap?: number;
  readonly height?: number | string;
}) {
  return (
    <div
      data-testid="vertical-line"
      className="vertical-line"
      style={{ margin: `0 ${gap}px`, height }}></div>
  );
}
