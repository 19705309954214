/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import type { CustomDeepDocRecordPage } from 'common/store/document/type';
import type { IRow, ISection } from 'common/store/summary/type';
import type { DeepDocJobBoundaryEntry } from 'lib/graphql/__generated__/graphql';

export const convertSectionToClientResult = (
  sections: ISection[],
  clientBoundariesKeyValue: Record<string, DeepDocJobBoundaryEntry>
) => {
  const newSections = [];
  for (const section of sections) {
    const boundariesGroupSet: { title: string; boundaries: DeepDocJobBoundaryEntry[] }[] = [];
    let currentDocType = '';
    let currentGroupSet: DeepDocJobBoundaryEntry[] = [];
    for (const item of section.items) {
      if (item.doctype === currentDocType) {
        currentGroupSet.push(clientBoundariesKeyValue[item.boundaryId]);
      } else {
        if (currentGroupSet.length > 0) {
          boundariesGroupSet.push({ title: currentDocType, boundaries: currentGroupSet });
        }
        currentDocType = item.doctype;
        currentGroupSet = [clientBoundariesKeyValue[item.boundaryId]];
      }
    }
    if (currentGroupSet.length > 0) {
      boundariesGroupSet.push({ title: currentDocType, boundaries: currentGroupSet });
    }
    const copySection = { ...section, types: boundariesGroupSet, title: section.name } as Omit<
      ISection,
      'collapse' | 'createdAt' | 'id' | 'name' | 'items'
    > & { collapse?: boolean; createdAt?: string; id?: string; name?: string; items?: any };
    delete copySection.collapse;
    delete copySection.createdAt;
    delete copySection.id;
    delete copySection.name;
    delete copySection.items;
    newSections.push(copySection);
  }
  return newSections;
};

export const convertClientResultToSection = (
  clientSections: any,
  boundariesMap?: Record<
    string,
    DeepDocJobBoundaryEntry & {
      range?: string | undefined;
    }
  >
) => {
  const clientBoundaries = {} as Record<string, DeepDocJobBoundaryEntry>;
  const formattedSections = clientSections.map((section: { title: string; types: any[] }) => {
    const sectionId = `container-${section.title.split(' ').join('-')}`;
    const structSection = {
      ...section,
      id: sectionId,
      name: section.title,
      collapse: false,
      createdAt: new Date('09/04/2023').toISOString(),
      items: [] as unknown as IRow[],
      types: undefined
    };
    delete structSection.types;
    for (const type of section.types) {
      const boundaries = type.boundaries;
      structSection.items.push(
        ...boundaries.map((boundary: DeepDocJobBoundaryEntry) => {
          const boundaryId = boundary.id;
          clientBoundaries[boundaryId as string] = boundary;
          return {
            id: `item-${boundaryId}`,
            boundaryId: boundaryId,
            sectionId: sectionId,
            doctype: type.title ?? '-',
            date: boundary.dateOfService ?? '-',
            range: boundariesMap?.[boundaryId as string]?.range ?? '-',
            physician: boundary.physician,
            facility: boundary.facility,
          } as IRow;
        })
      );
    }
    return structSection;
  });
  return { sections: formattedSections, boundariesRecord: clientBoundaries };
};

export const extractPagesFromSection = (sections: any[] | undefined, pagePosition: Record<string, { pos: number; presignedUrl: string; }> | undefined) => {
  const sectionsPages: CustomDeepDocRecordPage[] | undefined = sections?.reduce((acc: any, section: { types: any; }) => {
    const pagez = []
    for (const type of section.types) {
      const boundariez = type.boundaries;
      pagez.push(...boundariez.flatMap((boundary: {
        type: string;
        id: string; pages: any[]; 
}) => {
        return boundary.pages.map((page: { id: string | number; }) => {
          return {...pagePosition?.[page.id], ...page, boundaryId: boundary.id, type: boundary.type}
        })
      }))
    }
    return [...acc, ...pagez]
  }, [])
  return sectionsPages
}
